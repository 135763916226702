@import "./vars/variables";
@import "./vars/functions";

@for $i from 1 through 60 {
    @media (min-width: 1600px + ($i * 100)) {
        html {
            font-size: #{$browser-context + $i}px;
        }
    }
}

@media (max-width: 992px) {
    .order-filters .row{
        flex-wrap: nowrap;
    }
}

@media (max-width: 768px) {
    .order-filters .col-4{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .order-filters .row{
        flex-wrap: wrap;
    }
    .d-flex{
        flex-direction: column;
    }
    .btn.btn-black{
        margin-left: 0;
    }
    .btn.btn-black{
        margin-top: 1rem;
    }
    .container, .container-sm{
        max-width: 100%;
    }
    .card-info-content .col-6{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .card-info-content-item .w-100 .d-flex{
        align-items: stretch !important;
    }
    .card-info-content-items{
        padding: 1.875rem 0;
    }
}

// .container {
//     max-width: rem(1320);
//     padding-left: rem(15);
//     padding-right: rem(15);
// }

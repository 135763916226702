@import "./vars/variables";
@import "./vars/functions";
@import "./fonts";
@import "./input-range";
@import "./checkbox";
@import "./spinner";
@import "./pdf-pagination";
@import "./tabs";

@import "./header/header";
@import "./content/filters";
@import "./content/card";

//Helpers
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.full-height {
    height: 100%;
}

.no-padding {
    padding: 0 !important;
}

* {
    margin: 0;
    padding: 0;
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
    overflow: hidden !important;
}

// .container-fluid {
//     padding: 0 4.44rem;
// }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-left: rem(15);
    padding-right: rem(15);
}

.row {
    margin-left: rem(-15);
    margin-right: rem(-15);

    &.no-gutters {
        margin-left: 0;
        margin-right: 0;

        & > .col,
        & > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.no-parent {
        & > .col,
        & > [class*="col-"] {
            position: static;
        }
    }
}
.small-gutters {
    .row {
        margin-left: rem(-7.5);
        margin-right: rem(-7.5);
        .col,
        .col-1,
        .col-10,
        .col-11,
        .col-12,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-auto,
        .col-lg,
        .col-lg-1,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-auto,
        .col-md,
        .col-md-1,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-auto,
        .col-sm,
        .col-sm-1,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-auto,
        .col-xl,
        .col-xl-1,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-auto {
            padding-left: rem(7.5);
            padding-right: rem(7.5);
        }
    }
}
.row,
.d-flex {
    .pull-left {
        margin-right: auto;
    }

    .pull-right {
        margin-left: auto;
    }
}
.form-control {
    border-color: rgba($color: #000000, $alpha: 0.1);
    font: {
        family: "Roboto";
        size: rem(16);
    }
    color: $black;
	outline: none;

    &::placeholder {
		color: rgba(0, 0, 0, 0.50);
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: rem(16);
		font-style: normal;
		font-weight: 400;
		line-height: rem(26); /* 162.5% */
    }
    &:hover,
    &:focus {
        color: $black;
		outline: none;
		border-color: transparent;
		box-shadow: none;
    }
}
.is-sticky {
    position: fixed;
    top: 10px;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.btn {
    font-size: rem(14);
    color: #fff;
    position: relative;
    // width: 100%;

	&-password {
		position: absolute;
		top: 50%;
		right: rem(15);
		transform: translateY(-50%);
		border: none;
		background-color: transparent;
	}

    .spinner {
        width: rem(30);
        height: rem(30);
        margin: -15px 0 0 -15px;
        circle {
            stroke: #fff;
        }
    }

    @include transition();
    &.btn-primary {
		background: #4F7CAC;
        border-radius: rem(4);
        border: none;
		padding: rem(12) rem(15);
        font-size: rem(16);
        &:hover,
        &:focus {
            background: darken(#4F7CAC, 10);
            color: #fff;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-primary-tinned {
        background: $tinned-primary;
        border-radius: rem(4);
        border: none;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(20);
        padding-right: rem(20);
        color: $primary-color !important;
        border: none;
        font: {
            family: "Roboto";
            size: rem(16);
        }
        &:hover,
        &:focus {
            background: darken($tinned-primary, 10);
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-primary-transparent {
        background: transparent;
        border-radius: rem(4);
        border: none;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(20);
        padding-right: rem(20);
        color: $primary-color !important;
        border: rem(2) solid $primary-color;
        font: {
            family: "Roboto";
            size: rem(16);
        }
        &:hover,
        &:focus {
            background: darken($primary-color, 10);
            color: #fff !important;
            border-color: transparent;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    i {
        @include transition();
    }

    &.left {
        text-align: left;
    }

    & + & {
        margin-left: rem(15-12);
    }
    &.btn-blue-transparent {
        border: none;
        background: transparent;
        color: $secondary-color;
        margin: 0;
        padding: 0;
        font: {
            family: "Roboto Bold";
            size: rem(14);
        }

        &:hover,
        &:focus {
            background: transparent;
        }
    }
    &.btn-blue {
        background: $secondary-color;
        border-radius: rem(4);
        border: none;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(20);
        padding-right: rem(20);
        font: {
            family: "Roboto Bold";
            size: rem(14);
        }
        &:hover,
        &:focus {
            background: darken($secondary-color, 10);
            color: #fff;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-blue-white {
        background: $white;
        border-radius: rem(4);
        border: rem(2) solid $secondary-color;
        padding-top: rem(14);
        padding-bottom: rem(14);
        color: $secondary-color;
        font: {
            family: "Roboto Bold";
            size: rem(18);
        }
        &:hover,
        &:focus {
            background: darken($white, 10);
            color: $secondary-color;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-gray {
        border: none;
        border-radius: rem(8);
        background: $gray;
        color: $black;
        padding: rem(9) rem(24);
        font: {
            size: rem(14);
        }
        &:hover,
        &:focus {
            background: rgba($color: $black, $alpha: 0.2);
        }
    }
    &.btn-gray-transparent {
        background: transparent;
        border-radius: rem(4);
        border: none;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(20);
        padding-right: rem(20);
        border: rem(2) solid $black;
        color: #000000;
        opacity: 0.4;
        font: {
            family: "Roboto Bold";
            size: rem(14);
        }
        &:hover,
        &:focus {
            background: rgba($color: $black, $alpha: 0.2);
        }
        &:disabled {
            opacity: 0.5;
        }
    }
    &.btn-gold {
        display: inline-block;
        width: auto;
        border: rem(2) solid $gold;
        border-radius: rem(100);
        background: transparent;
        color: $black;
        padding: rem(10) rem(40);
        font: {
            family: "Roboto";
            size: rem(14);
        }
        &:hover,
        &:focus {
            background: $gray;
        }
    }
    &.btn-cancel {
        display: inline-block;
        width: auto;
        border: rem(2) solid rgba($color: #000000, $alpha: 0.2);
        border-radius: rem(100);
        background: transparent;
        color: $black;
        padding: rem(10) rem(40);
        font: {
            family: "Roboto";
            size: rem(14);
        }
        &:hover,
        &:focus {
            background: $gray;
        }
    }

	&.btn-black {
		border-radius: 4px;
		background: #000;
		padding: rem(13) rem(10);
		margin-left: rem(15);

		img {
			margin-right: rem(10);
		}

		&:hover,
		&:focus {
			background: rgba(0, 0, 0, 0.85);
			color: $white;
		}
	}
}
body > #root > div {
    height: 100vh;
}
html {
    font-size: #{$browser-context}px;
}

body {
    font-family: $primary;
    color: #000;

    &.modal-show,
    &.menu-show {
        overflow: hidden;
    }
}

img {
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    margin-bottom: 0;
}

// h1{

// }

h2 {
    font-size: rem(44);
}

h3 {
    font-size: rem(32);
    font-weight: 600;
}

h4 {
    font-size: rem(24);

    span {
        font-weight: 500;
    }
}

h5 {
    font-size: rem(18);
}

h6 {
    font-size: rem(10);
}

a {
    color: $link-color;
    text-decoration: none !important;
    @include transition();

    &:hover,
    &:focus {
        color: $secondary-color;
        text-decoration: none !important;
    }
}
.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
p {
    line-height: rem(26);
}
.logo {
    display: flex;
    max-width: rem(258);
}
.lvb-logo {
    display: flex;
    a {
        display: flex;
        color: #000;
    }
    .logo-txt {
        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: left;
        padding-left: rem(10);
        h5 {
            margin-bottom: rem(5);
            font: {
                family: "Roboto Bold";
                size: rem(12);
            }
        }
        h6 {
            font: {
                family: "Roboto";
                size: rem(8);
            }
        }
    }
}
.login-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-fluid {
        z-index: 10;
    }
    .logo {
        margin-bottom: rem(125);
    }
    .sign-bg {
        padding: rem(50) rem(140);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex: 1;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($black, 0.2);
        }
    }
}
.error-message {
    color: $red;
    display: block;
    margin-top: rem(5);
}
.sign-in-form {
	max-width: rem(463);
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #FFF;
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.10);
    padding: rem(30);
    border-radius: rem(8);

	&-logo {
		margin-bottom: rem(30);
	}

    .btn {
        width: 100%;
        font-size: rem(16);
    }

    h4 {
        margin-bottom: rem(15);
		font-size: rem(24);
		font-weight: 600;
		line-height: rem(32);
    }

    .form-group {
        margin-bottom: rem(15);

        label {
            font-size: rem(14);
            margin-bottom: rem(5);
        }

        input {
            min-height: rem(50);
        }
    }

    button[type="submit"] {
		margin-top: rem(15);
        min-height: rem(50);
    }

    .input-group-append {
        .form-control {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.sign-in-footer {
    display: flex;
    background-color: #fff;
    padding-left: rem(140);
    padding-right: rem(140);
    justify-content: space-between;
    padding-top: rem(15);
    padding-bottom: rem(15);
    .cm-col {
        display: flex;
        .vsk-logo {
            background-size: contain;
            background-repeat: no-repeat;
            width: 150px;
            background-position: center;
        }
        &:last-child {
            justify-content: flex-end;
        }
    }
    p {
        font: {
            family: "Montserrat";
            size: rem(12);
        }
        margin-bottom: 0;
    }
}

.number_link{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.link_innerp h5{
    font-size: 1rem;
    color: #000000;
    font-weight: 700;
}

.link_ups p{
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
}
.admin-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .admin-footer {
        padding-top: rem(15);
        padding-bottom: rem(15);
        .cm-col {
            p {
                margin-bottom: 0;
            }
            display: flex;
            align-items: center;
            &.right {
                justify-content: flex-end;
            }
        }
    }
}
.content {
    background-color: $admin-bg;
    flex: 1;
    height: 100%;
    padding-top: rem(30);
    padding-bottom: rem(30);

    .top-heading {
        margin-bottom: rem(30);
    }
    h3 {
        margin-bottom: rem(5);
        font: {
            family: "Roboto Bold";
            size: rem(24);
        }
    }
}

.no-document {
    text-align: center;
    img {
        margin-bottom: rem(20);
    }
    h2 {
        color: $heading-green;
        font: {
            family: "Roboto Bold";
            size: rem(20);
        }
        margin-bottom: rem(5);
    }
    p {
        font: {
            family: "Roboto";
            size: rem(12);
        }
        margin-bottom: rem(20);
    }
}
.divider {
    background-color: #d9d9d9;
    height: rem(1);
    width: 100%;
    margin-top: rem(14);
    margin-bottom: rem(14);
}

.collapsed {
	p {
		color: #000;
		opacity: 0.5;
		font-size: rem(12);
		font-style: normal;
		font-weight: 400;
		line-height: rem(20);
		margin-bottom: 0;
	}
}

.accordion {
    width: 100%;
	padding: 0 rem(30);
	min-height: 50vh;

    .card {
        margin-bottom: rem(10);
        border: 0;
        border-radius: rem(4);
        .card-header {
            background: $white;
            cursor: pointer;
            border-bottom: 0;
            padding: 0;
            [data-toggle="collapse"] {
                padding: rem(12) rem(24);
            }
        }
        .card-body {
            padding: rem(12) rem(24);
            padding-top: 0;
            .row {
                margin-bottom: rem(15);
                .item {
                    .sub-heading {
                        font: {
                            size: rem(10);
                        }
                        margin-bottom: rem(5);
                        &.big {
                            margin-bottom: rem(10);
                            font: {
                                family: "Roboto";
                                size: rem(14);
                            }
                        }
                    }
                    .heading {
                        font: {
                            size: rem(14);
                        }
                    }
                    .btn {
                        font: {
                            family: "Roboto";
                            size: rem(14);
                        }
                    }
                }
            }
        }
    }
    .col {
        font-size: rem(14);
    }
    .id {
		color: #003166;
		font-size: rem(16);
		font-style: normal;
		font-weight: 600;
		line-height: rem(24);
    }
    &.status {
        span {
            color: $black;
        }
        .not-completed {
            color: $red;
        }
        .completed {
            color: $primary-color;
        }
    }
    .arrow {
        svg {
            path {
                fill: rgba($color: $black, $alpha: 0.3);
            }
            @include transition();
            &.active {
                transform: rotate(180deg);
                path {
                    // fill: $primary-color;
                    fill: rgba($color: $black, $alpha: 1);
                }
            }
        }
    }

	&-loading {
		height: calc(100vh - 50px - 30px - 250px);
	}
}
.information {
    overflow: hidden;

    &.list-wrapper {
        .info-top-header {
            .btn {
                padding-left: rem(40);
                padding-right: rem(40);
            }
        }
    }

    .info-top-header {
        margin-bottom: rem(30);
        display: flex;
        align-items: center;
        .btn {
            padding-left: rem(20);
            padding-right: rem(20);
            border-radius: rem(4);
            padding-top: rem(9);
            padding-bottom: rem(9);
            font: {
                size: rem(14);
            }
        }
        h3 {
            font: {
                size: rem(24);
            }
        }
    }
}

.case-item {
    border-radius: rem(4);
    padding: rem(15);
    border: rem(2) solid $gray;
    height: 100%;
    cursor: pointer;
    h5 {
        font: {
            family: "Roboto Bold";
            size: rem(16);
        }
        margin-bottom: rem(5);
    }
    p {
        font: {
            size: rem(10);
        }
        line-height: rem(18);
        margin-bottom: 0;
    }
    @include transition();
    &.active {
        background-color: rgba(#13ac97, 0.05);
        border-color: transparent;
    }
}

.custom-card {
    border: none;
    padding: rem(30) rem(20);
    border-radius: rem(4);
    margin-bottom: rem(20);
    .card-body {
        padding: 0;
    }
    &-small {
        position: sticky;
        top: rem(10);
        border: none;
        padding: rem(20);
        border-radius: rem(4);
        margin-bottom: rem(20);
        .btn {
            min-height: rem(47);
            margin-bottom: rem(15);
            width: 100%;
            font-size: rem(18);
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.pre-form {
    .form-group {
        margin-bottom: rem(20);
        &:last-child {
            margin-bottom: 0;
        }
    }
    h4,
    label {
        margin-bottom: rem(20);
    }
    h4 {
        font: {
            size: rem(20);
        }
    }
    label {
        display: block;
        font: {
            size: rem(14);
        }
    }
}

.form-block {
	position: relative;
	display: а
;
}

.info-wrapper {
    .info-block {
        span {
            display: block;
            font: {
                size: rem(14);
            }
            margin-bottom: rem(10);
        }
        h4 {
            margin-bottom: rem(15);
            font: {
                size: rem(16);
            }
            // margin-bottom: 0;
        }
        .pre-price {
            font: {
                family: "Roboto";
                size: rem(36);
            }
            color: $black;
            margin-bottom: rem(15);
        }
    }
}
.create-form {
    h5 {
        font: {
            family: "Roboto Bold";
            size: rem(14);
        }
        margin-bottom: rem(15);
    }
    h4 {
        font: {
            family: "Roboto Bold";
            size: rem(20);
        }
    }
    .row {
        margin-bottom: rem(30);
    }
    .form-control {
        height: rem(50);
    }
    .form-group {
        .btn-primary {
            width: auto;
            padding-left: rem(26);
            padding-right: rem(26);
        }
    }
}
.custom-modal {
    width: rem(530);
    border: none;
    padding: rem(20);
    border-radius: rem(4);
    background-color: #fff;
    &:focus,
    &:hover {
        outline: none;
    }
    label {
        font: {
            size: rem(14);
        }
        margin-bottom: rem(5);
    }
    h5 {
        font: {
            family: "Roboto Bold";
            size: rem(16);
        }
    }
    a {
        display: block;
        color: $secondary-color;
        word-wrap: break-word;
        font: {
            size: rem(18);
        }
    }
    .pre-price {
        font: {
            family: "Roboto Bold";
            size: rem(32);
        }
    }
    a.btn {
        color: #fff;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(48);
        padding-top: 0;
        padding-bottom: 0;
        &.btn-primary-transparent {
            width: 100%;
        }

        &.btn-primary {
            width: 100%;
        }
    }
}

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-page {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
        margin: rem(15) 0;
    }
}

.pagination {
    justify-content: center;
    .page-item {
        .page-link {
            border-color: $primary-color;
            color: $primary-color;
        }
        &.active {
            .page-link {
                color: #fff;
                background-color: $primary-color;
            }
        }
    }
}

.collapse {
    .item {
        .btn {
            height: rem(45);
            display: flex;
            align-items: center;
        }
    }
}
.fixed-sidebar {
    position: fixed;
    left: 0;
    top: 0;
}
.admin-sidebar {
    background-color: #fff;
    height: 100vh;
    padding: rem(24);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .lvb-logo {
        margin-bottom: rem(30);
        max-width: rem(161);
    }
    .current-user {
        h5 {
            margin-bottom: rem(5);
            font: {
                family: "Roboto";
                size: rem(16);
            }
        }
        .user-email {
            font: {
                family: "Roboto";
                size: rem(12);
            }
            color: #006db7;
        }
    }
    .nav-link {
        padding: 0;
        margin-bottom: rem(20);
        background-color: transparent;
        border: none;
        &:last-child {
            margin-bottom: 0;
        }
        .nav-item {
            display: flex;
            align-items: center;
            .icon {
                opacity: 0.3;
                margin-right: rem(15);
                @include transition();
            }
            .title {
                color: $black;
                font: {
                    family: "Roboto";
                    size: rem(14);
                }
                @include transition();
            }
        }
        &:hover,
        &:focus,
        &.current {
            .nav-item {
                .icon {
                    opacity: 1;
                }
                .title {
                    color: $primary-color;
                }
            }
        }
        &.without-opacity {
            .icon {
                opacity: 1;
            }
        }
    }
    .sidebar-bottom {
        .divider {
            margin-bottom: rem(20);
        }
    }
    .sidebar-footer {
        .nav-link {
            margin-bottom: rem(43);
        }
        .vsk-logo {
            margin-bottom: rem(15);
        }

        p {
            line-height: rem(18);
            margin-bottom: 0;
            font: {
                family: "Montserrat";
                size: rem(10);
            }
        }
    }
}
.today-orders {
    .card {
        height: 100%;
        border: none;
        border-radius: rem(8);
        .card-body {
            padding: rem(30);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .card-top {
                h4 {
                    font: {
                        size: rem(20);
                    }
                    margin-bottom: rem(15);
                }
                p {
                    font: {
                        size: rem(14);
                    }
                    line-height: rem(24);
                    margin-bottom: rem(30);
                }
            }
            .card-bottom {
                button {
                    max-width: rem(255);
                    width: 100%;
                }
            }
        }
    }
    .orders {
        margin-top: rem(60);
        h4 {
            font: {
                size: rem(20);
            }
            margin-bottom: rem(30);
        }
    }
}
.react-select-prefix__control {
    height: rem(44);
}
.create-form {
    .react-select-prefix__control {
        height: rem(50);
    }
}
.react-pdf__Page__annotations.annotationLayer {
    display: none;
}
.react-pdf__Document {
    margin-bottom: rem(24);
}
.react-datepicker {
	&__current-month {
		display: none;
	}

	.react-datepicker__year-dropdown-container--select,
	.react-datepicker__month-dropdown-container--select,
	.react-datepicker__month-year-dropdown-container--select,
	.react-datepicker__year-dropdown-container--scroll,
	.react-datepicker__month-dropdown-container--scroll,
	.react-datepicker__month-year-dropdown-container--scroll {
		margin: 0 10px;
	}

	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__month-year-read-view--down-arrow {
		top: 4px;
	}
}
.rc-input-number-input {
    text-align: center;
}
.custom-calendar {
    display: flex;
    background-color: #fff;
    border-radius: rem(4);
    //padding-left: rem(10);
    //padding-right: rem(10);
    align-items: center;
    justify-content: space-between;

    .delimetr {
        height: rem(50);
        width: rem(1);
        background-color: rgba(0, 0, 0, 0.05);
        position: relative;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

		img {
			min-width: rem(20);
		}
    }

    .picker-wrapper {
        display: flex;
		padding: rem(13) rem(15);

        span {
            font-size: rem(14);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: rem(6);
			white-space: nowrap;
			color: #000;
			font-style: normal;
			font-weight: 400;
			line-height: rem(22);
        }

        input {
            outline: none;
            font-size: rem(14);
            background-color: transparent;
			max-height: rem(22);
        }
    }
}
@import "responsive";

.card-info {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h4 {
			color: #000;
			font-size: rem(32);
			font-style: normal;
			font-weight: 500;
			line-height: rem(40);
		}

		.btn {
			padding: rem(14) rem(10);
			min-width: rem(205);
		}
	}

	&-content {
		width: 100%;
		padding-top: rem(30);

		h5 {
			color: #000;
			font-size: rem(16);
			font-style: normal;
			font-weight: 400;
			line-height: rem(28);
			margin-bottom: rem(17);
			opacity: 0.5;
		}

		p {
			margin-bottom: 0;
		}

		&-items {
			padding: rem(30);
			border-radius: 4px;
			background: #FFF;
			height: calc(100% - 28px - 15px);
		}

		&-item {
			margin-bottom: rem(15);
			display: flex;
			align-items: center;
			position: relative;

			&.first {
				.title {
					opacity: 1;
				}
			}

			&.date-start:before, &.date-end:before {
				content: '';
				margin-right: rem(15);
				width: rem(10);
				height: rem(10);
				border-radius: 50%;
			}

			&.date-start:before {
				background-color: #007BFF;
				margin-bottom: rem(10);
			}


			&.date-end:before {
				margin-top: rem(20);
				background-color: #F20000;
			}


			&.date-start:after {
				content: '';
				position: absolute;
				border-radius: 100px;
				background: linear-gradient(180deg, #007BFF 0%, #F20000 100%);
				top: calc(100% - 24px);
				left: rem(4);
				width: rem(2);
				height: rem(67);
			}

			.title {
				color: #000;
				font-size: rem(14);
				font-style: normal;
				font-weight: 400;
				line-height: rem(22);
				opacity: 0.5;
			}

			.text {
				color: #000;
				font-size: rem(20);
				font-style: normal;
				font-weight: 400;
				line-height: rem(30);

				&.textRight {
					margin-left: auto;
					color: #4F7CAC;
					text-align: right;
					font-size: rem(14);
					font-style: normal;
					font-weight: 500;
					line-height: rem(22);
				}
			}
		}
	}
}

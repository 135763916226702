@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.eot");
    src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"),
        url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto Medium";
    src: url("../fonts/Roboto-Medium.eot");
    src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff"),
        url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto Bold";
    src: url("../fonts/Roboto-Bold.eot");
    src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff"),
        url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.check-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: rem(35) !important;
}

/* Hide the browser's default checkbox */
.check-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: rem(20);
  width: rem(20);
  background-color: #fff;
  border-radius: rem(4);
  border: rem(2) solid rgba($color: #000000, $alpha: 0.2);
}

/* On mouse-over, add a grey background color */
.check-wrapper:hover input ~ .checkmark {
  background-color: #fff;
}

.check-wrapper input:checked ~ .checkmark {
  background-color: $primary-color;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.pdf-pagination {
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: rem(5);
    border-radius: rem(100);
    p {
        margin-bottom: 0;
        padding-left: rem(10);
        padding-right: rem(10);
        font: {
            family: "PF Sans Pro";
            size: rem(14);
        }
        color: $primary-color;
    }
    .pdf-arrow {
        background-color: transparent;
        border: none;
        width: rem(24);
        height: rem(24);
        display: flex;
        justify-content: center;
        align-items: center;
        &:disabled {
            opacity: 0.5;
        }
        transition: all 0.3s ease;
        &.left {
            transform: rotate(180deg);
        }
    }
}

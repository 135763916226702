.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(11) rem(30);

	&__left, &__right, &__profile, &__other, &__map {
		display: flex;
		align-items: center;
	}

	&__logo {
		display: flex;
		align-items: center;
		cursor: pointer;

		img {
			margin-right: rem(15);
			max-width: rem(48);
		}

		p {
			color: #000;
			font-size: rem(16);
			font-style: normal;
			font-weight: 600;
			line-height: rem(22);
			margin-bottom: 0;
		}
	}

	&__profile {
		border-radius: 50%;
		margin-left: rem(30-12);
		overflow: hidden;
		background: #CCD9E0;
	}

	&__separator {
		margin: 0 rem(24 - 12);
		height: rem(44);
		width: rem(1);
		background-color: #D9D9D9;
		opacity: 0.1;
	}
}

.order-filters {
	padding: 0 rem(30);

	input,
	button {
		height: rem(50);
	}
	input {
		border: none;
	}

	.input-group {
		.input-group-text {
			background-color: #fff;
			border: none;
			padding-right: 0;
		}
		.form-control {
			border-left: none;
		}
	}

	.react-datepicker {
		&__navigation {
			height: 38px;
		}
	}

	.react-datepicker-wrapper {
		// input{
		//     font-size: rem(13);
		// }
		// .react-datepicker__close-icon{
		//     right:rem(-30);
		//     z-index: 200;
		// }
	}
}

.filter-select {
	svg {
		path {
			fill: rgba($color: #000000, $alpha: 0.3);
		}
	}

	.react-select-prefix__placeholder {
		white-space: nowrap;
	}
}
